<script setup lang="ts">
import { useTheme } from "~/composables/useTheme"

const theme = useTheme()
</script>

<template>
    <div class="static">
        <img src="/sunga_logo_light.png" :hidden="!(theme === 'dark')" alt="Light Sunga Logo" />
        <img src="/sunga_logo_dark.png" :hidden="theme === 'dark'" alt="Dark Sunga Logo" />
    </div>
</template>

<style scoped lang="scss"></style>
